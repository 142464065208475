import { FaGift, FaMoneyBillTransfer } from 'react-icons/fa6';
import { GiProfit } from 'react-icons/gi';
import { MdPendingActions } from 'react-icons/md';
import { BiMoneyWithdraw } from 'react-icons/bi';

const BalanceSummary = ({ userData }) => {
  const {
    profits,
    deposits,
    pendingDeposits,
    pendingWithdrawals,
    withdrawals,
    bonus,
  } = userData;

  return (
    <div className='balance-summary'>
      <h3 className='title'>Balance Summary</h3>
      <div className='grid'>
        <div className='item'>
          <div className='crypto'>
            <GiProfit />
            <p>Total Balance</p>
          </div>
          <div className='info'>
            <p>{(profits + deposits + bonus)?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>

        <div className='item'>
          <div className='crypto'>
            <FaMoneyBillTransfer />
            <p>Total Deposits</p>
          </div>
          <div className='info'>
            <p>{deposits?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>

        <div className='item'>
          <div className='crypto'>
            <MdPendingActions />
            <p>Pending Deposits</p>
          </div>
          <div className='info'>
            <p>{pendingDeposits?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>

        <div className='item'>
          <div className='crypto'>
            <BiMoneyWithdraw />
            <p>Total Withdrawals</p>
          </div>
          <div className='info'>
            <p>{withdrawals?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>

        <div className='item'>
          <div className='crypto'>
            <MdPendingActions />
            <p>Pending Withdrawals</p>
          </div>
          <div className='info'>
            <p>{pendingWithdrawals?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>

        <div className='item'>
          <div className='crypto'>
            <FaGift />
            <p>Bonus</p>
          </div>
          <div className='info'>
            <p>{bonus?.toLocaleString(`en-US`)}</p>
            <span>USD</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSummary;
