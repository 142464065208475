'use client';
import { useState } from 'react';

export const FAQ = () => {
  const [activeItem, setActiveItem] = useState(0);

  const faqs = [
    {
      question: `How does Raritrade work?`,
      answer: `Raritrade utilizes automated trading algorithms to execute cryptocurrency trades on behalf of users based on predefined strategies and parameters.`,
    },
    {
      question: `What cryptocurrencies can I trade on Raritrade?`,
      answer: `Raritrade supports trading for a wide range of cryptocurrencies, including popular ones like Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), Ripple (XRP), and many others.`,
    },
    {
      question: `Is Raritrade safe and secure to use?`,
      answer: `Yes, Raritrade employs robust security measures, including encryption protocols, two-factor authentication, and adherence to industry best practices to ensure the safety of users' funds and personal information.`,
    },
    {
      question: `How do I get started with automated trading on Raritrade?`,
      answer: `To get started, simply sign up for an account on the Raritrade website, deposit funds, configure your trading preferences, and activate your chosen trading strategies.`,
    },
    {
      question: `Can I customize my trading strategies on Raritrade?`,
      answer: `Yes, Raritrade allows users to customize their trading strategies by adjusting parameters such as risk tolerance, investment amount, trading frequency, and more.`,
    },
    {
      question: `What fees are associated with using Raritrade?`,
      answer: `Raritrade charges competitive fees for its services, which may include a percentage of profits generated from trades or a subscription-based fee structure, depending on the chosen plan.`,
    },
    {
      question: `Does Raritrade offer customer support?`,
      answer: `Yes, Raritrade provides comprehensive customer support to assist users with any inquiries, technical issues, or account-related concerns they may have.`,
    },
    {
      question: `What is the minimum investment required to use Raritrade?`,
      answer: `The minimum investment required to use Raritrade may vary depending on the specific features and services offered, but typically, users can get started with a relatively small initial investment.`,
    },
    {
      question: `Are there any performance metrics or historical data available for the automated trading strategies on Raritrade?`,
      answer: `Yes, Raritrade provides users with access to performance metrics, including historical data, backtesting results, and real-time analytics, to help them evaluate the effectiveness of their chosen trading strategies.`,
    },
  ];

  return (
    <section className='faq'>
      <div className='title'>
        <h4>FAQ</h4>
        <h2>Frequently Asked Questions</h2>
      </div>

      <div className='q-a'>
        {faqs.map((item, index) => {
          return (
            <div
              className={`item ${index === activeItem ? `active` : ``}`}
              key={index}
            >
              <h3 onClick={() => setActiveItem(index)}>{item.question}</h3>
              <p>{item.answer}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
