import investCrypto from '../../../assets/images/invest-cryptocurrencies.png';
import investStocks from '../../../assets/images/invest-stocks.png';
import investCryptoIndices from '../../../assets/images/invest-crypto-indices.png';
import investEtfs from '../../../assets/images/invest-etfs.png';
import investCommodities from '../../../assets/images/invest-commodities.png';
import investPreciousMetals from '../../../assets/images/invest-precious-metals.png';
import { FAQ } from '../../../components/faq/Faq';
import { CiLock } from 'react-icons/ci';
import {
  FcGenealogy,
  FcUnlock,
  FcLockPortrait,
  FcProcess,
  FcSettings,
} from 'react-icons/fc';
import { Link } from 'react-router-dom';
import heroImgTwo from '../../../assets/images/hero-img-2.jpg';
import bitcoinLogo from '../../../assets/images/bitcoin-logo.svg';
import appleLogo from '../../../assets/images/apple-logo.svg';
import goldLogo from '../../../assets/images/gold-icon.svg';

const Home = () => {
  return (
    <>
      <main className='home-page'>
        <Header />
        <Hero />
        <Investments />
        <ComplianceProtection />
        <HowItWorks />
        <FAQ />
      </main>
    </>
  );
};

const Header = () => {
  return (
    <header className='header'>
      <div className='info'>
        <h1>
          Trade crypto with ease and access global investment opportunities.
        </h1>
        <p>
          Gain the edge in crypto trading with in-depth analysis and a suite of
          tools to take your strategies to the next level. Effortlessly build,
          scale, and optimize your crypto strategies with our powerful tools and
          insights.
        </p>

        <div className='btns'>
          <Link to='/sign-in'>
            <button className='trans'>
              <CiLock /> Sign In
            </button>
          </Link>
          <Link to='/sign-up'>
            <button className='linear'>Create an Account</button>
          </Link>
        </div>
      </div>
    </header>
  );
};

const Hero = () => {
  return (
    <section className='hero'>
      <div className='info'>
        <h2>Secure your future with responsible investments.</h2>
        <Link to='/sign-up'>
          <button className='green'>Invest Now</button>
        </Link>
      </div>
      <div className='image'>
        <div className='info'>
          <div className='assets'>
            <div className='asset'>
              <div className='icon'>
                <img src={bitcoinLogo} alt='bitcoin logo' />
              </div>
              <h3>Bitcoin</h3>
            </div>
            <div className='asset'>
              <div className='icon'>
                <img src={goldLogo} alt='gold' />
              </div>
              <h3>Gold</h3>
            </div>
            <div className='asset'>
              <div className='icon'>
                <img src={appleLogo} alt='apple inc. logo' />
              </div>
              <h3>Apple</h3>
            </div>
          </div>
        </div>
        <div className='img'>
          <img src={heroImgTwo} alt='' />
        </div>
      </div>
    </section>
  );
};

const Investments = () => {
  const investmentTypes = [
    {
      title: `Stocks`,
      brief:
        'Invest in partial ownership of your preferred companies without the need to purchase an entire share.',
      image: investStocks,
    },
    {
      title: `Cryptocurrencies`,
      brief:
        'Buy, sell & swap the cryptocurrencies you want anytime, anywhere.',
      image: investCrypto,
    },
    {
      title: `Crypto Indices`,
      brief: 'Auto-invest in the whole crypto market with a single click',
      image: investCryptoIndices,
    },
    {
      title: `Precious Metals`,
      brief:
        'Protect your wealth during uncertain times by investing in tangible assets like gold and silver',
      image: investPreciousMetals,
    },
    {
      title: `Commodities`,
      brief: 'Diversify with commodities to combat inflation',
      image: investCommodities,
    },
    {
      title: `ETFs`,
      brief: 'Own any amount of your favorite ETFs with fractional shares',
      image: investEtfs,
    },
  ];

  return (
    <section className='investments'>
      <div className='title'>
        <h4>INVEST </h4>
        <h2>
          All investments. <br /> Available on Raritrade.
        </h2>
      </div>

      <div className='grid'>
        {investmentTypes.map((type, index) => {
          const { brief, image, title } = type;
          return (
            <div className='card' key={index}>
              <div className='info'>
                <h2>{title}</h2>
                <p>{brief}</p>
              </div>
              <div className='img'>
                <img src={image} alt='' />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      title: `Register`,
      brief: `Sign up for an account on Raritrade by providing your email address, creating a password, and agreeing to the terms and conditions. Registration typically involves filling out a form with basic personal information.`,
      buttonLabel: `Get Started`,
      href: `/sign-up`,
    },
    {
      title: `Verify`,
      brief: `Complete the verification process by providing additional documentation to confirm your identity and comply with regulatory requirements. This step may involve uploading a government-issued ID, proof of address, and in some cases, undergoing a Know Your Customer (KYC) procedure.`,
    },
    {
      title: `Deposit Funds`,
      brief: `After your account is verified, deposit funds into your trading account using one of the supported payment methods. This usually includes bank transfers, credit/debit cards, or cryptocurrencies. Follow the platform's instructions to initiate the deposit process securely.`,
    },
    {
      title: `Automated Trading`,
      brief: `Set up your automated trading preferences by configuring parameters such as trading strategies, risk management rules, and allocation of funds. Choose from a range of predefined trading strategies or create your own customized approach. Once configured, the automated trading system will execute trades on your behalf based on the selected criteria.`,
    },
    {
      title: `Withdrawal`,
      brief: `When you're ready to withdraw funds from your trading account, navigate to the withdrawal section of the platform and initiate a withdrawal request. Specify the amount you wish to withdraw and select your preferred withdrawal method. Funds are typically transferred back to the original source of deposit or to a linked bank account. Ensure that you comply with any withdrawal limits and processing times specified by the platform.`,
    },
  ];

  return (
    <section className='how-it-works'>
      <div className='title'>
        <h4>HOW IT WORKS </h4>
        <h2>Get Started In Minutes</h2>
      </div>

      <div className='steps'>
        {steps.map((step, index) => {
          return (
            <div
              className={`item ${(index + 1) % 2 === 0 ? `reverse` : ``}`}
              key={index}
            >
              <div className='number'>
                <h2>0{index + 1}</h2>
                <div className='line'></div>
              </div>
              <div className='info'>
                <h3>{step.title}</h3>
                <p>{step.brief}</p>
                {step.buttonLabel && step.href ? (
                  <Link to={step.href}>
                    <button className='linear'>{step.buttonLabel}</button>
                  </Link>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const ComplianceProtection = () => {
  const data = [
    {
      title: `Advanced Fund Protection`,
      icon: <FcGenealogy />,
      brief: `Leveraging industry-leading security practices, we safeguard your assets through offline cold wallet storage. Our comprehensive Triple Fund Protection further bolsters the security framework, ensuring unparalleled peace of mind for a trust-worthy and reliable experience.`,
    },
    {
      title: `Data Privacy & Security`,
      icon: <FcUnlock />,
      brief: `Built with data privacy and security at the core, our products and services prioritize your control over your information. We are transparent about the data we collect, and clearly explain how it is used and shared, empowering you to make informed choices.`,
    },
    {
      title: `End-to-End Encryption`,
      icon: <FcLockPortrait />,
      brief: `Your private and personal information remains protected throughout its journey, secured by end-to-end encryption both in transit and at rest. To further safeguard your data, strict authorization controls ensure only you have the keys to access it.`,
    },
    {
      title: `Live Insights`,
      icon: <FcProcess />,
      brief: `Raritrade prioritizes your security with Live Insights, continuously analyzing user behavior to identify potential risks. If suspicious activity is detected, enhanced authentication safeguards your withdrawals for an extra layer of protection.`,
    },
    {
      title: `Trustworthy by Design`,
      icon: <FcSettings />,
      brief: `Built with trust in mind, our system integrates a secure development life cycle, rigorous security testing, and ongoing bug bounty programs, solidifying its reliability and safeguarding your information.`,
    },
  ];

  return (
    <section className='compliance-protection'>
      <div className='title'>
        <h4>SECURITY</h4>
        <h2>Our Commitment to Compliance and Your Protection</h2>
      </div>

      <div className='grid'>
        {data.map((item, index) => {
          return (
            <div className='item' key={index}>
              <div className='title-icon'>
                <h3>{item.title}</h3>
                {item.icon}
              </div>
              <p>{item.brief}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Home;
