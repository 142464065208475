import companyLogo from '../../../assets/images/company-logo.svg';
import { ForgotPasswordForm } from '../../../components/forms/Forms';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  return (
    <main className='auth-page'>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='Raritrade' />
      </Link>
      <ForgotPasswordForm />
    </main>
  );
};

export default ForgotPassword;
