import { SignUpForm } from '../../../components/forms/Forms';
import companyLogo from '../../../assets/images/company-logo.svg';
import { Link } from 'react-router-dom';

const SignUp = () => {
  return (
    <main className='auth-page'>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='Raritrade' />
      </Link>
      <SignUpForm />
    </main>
  );
};

export default SignUp;
