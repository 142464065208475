import {
  CryptoTable,
  TrendingCoins,
} from '../../../components/dashboard-components/crypto-comp/CryptoComp';
import React, { useEffect, useState } from 'react';
import { BsBoxArrowInDown, BsBoxArrowInUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BalanceSummary from '../../../components/dashboard-components/balance-summary/BalanceSummary';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';
import { useDisclosure } from '@mantine/hooks';
import { NotificationModal } from '../../../components/dashboard-components/modals/Modals';

const Overview = () => {
  const [userData, setUserData] = useState([]);
  const [notification, setNotification] = useState({});
  const [investmentGoal, setInvestmentGoal] = useState(``);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const [opened, { open, close }] = useDisclosure(false);

  const fetchUser = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/users/user-info?fields=bonus profits deposits withdrawals pendingDeposits pendingWithdrawals totalBalance moneyEarned notification investmentGoal accountTier`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotification(data.notification);
      setInvestmentGoal(data.investmentGoal);
      setUserData(data);
      if (data.notification.title && data.notification.content) {
        open();
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content overview-page'>
      <section className='wallets'>
        <div className='card'>
          <h3>Wallets in USD</h3>
          <div className='items'>
            <div className='item'>
              <h4>{userData.accountTier}</h4>
              <p>Account Tier</p>
            </div>
            <div className='item'>
              <h4>
                ${userData.profits?.toLocaleString(`en-US`)}.<span>00</span>
              </h4>
              <p>Profit Wallet</p>
            </div>
          </div>
          <div className='money-earned'>
            <p>
              You Earned {userData.moneyEarned?.toLocaleString(`en-US`)} USD
              This Week
            </p>
          </div>
        </div>
      </section>

      <section className='balances'>
        <div className='fiat-balance'>
          <div className='investment-goal'>
            <div className='info'>
              <p>Investment Goal</p>
              <h3>{investmentGoal ? investmentGoal : `Not yet set`}</h3>
            </div>
          </div>
          <div className='btns'>
            <Link to='/dashboard/deposit'>
              <button className='linear'>
                <BsBoxArrowInDown />
                Deposit
              </button>
            </Link>
            <Link to='/dashboard/withdrawal'>
              <button className='linear'>
                <BsBoxArrowInUp />
                Withdraw
              </button>
            </Link>
          </div>
        </div>
        <BalanceSummary userData={userData} />
      </section>

      <TrendingCoins />
      <CryptoTable />
      {notification.title && notification.content && !notification.seen && (
        <NotificationModal
          opened={opened}
          closeFunc={close}
          notification={notification}
        />
      )}
    </main>
  );
};

export default Overview;
